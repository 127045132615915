<template>
<mobile v-if="gl.isMobile" />
<desktop v-else />
</template>

<script>
import { useGl } from '@/store/gl';

// components
import Desktop from './desktop.vue';
import Mobile from './mobile.vue';

export default {
    name: 'marketpla-buy-index',
    components: {
        Mobile,
        Desktop,
    },
    setup() {
        const gl = useGl();
    
        return {
            gl,
        };
    },
};
</script>
<!-- <template>
<router-view></router-view>
</template>

<script>
export default {
    name: 'MarketplaceSellIndex',
    setup() {
        // 
    },
};
</script> -->